import React, { useState } from "react";
import mailicon from "../../../assets/icons/mail-con.svg";
import CustomInput from "../../Inputs/CustomInput";
import CustomButton from "../../Buttons/CustomButton";

function InviteInstitutionals({
  email,
  setEmail,
  suceess,
  isLoading,
  error,
  setError,
  onClick,
}) {
  return suceess ? (
    <div
      className="flex flex-row justify-around items-center p-[17px] gap-3"
      style={{
        borderRadius: "14px",
        border: "1.4px solid ",
        borderColor: "#00E7FF",
        boxShadow: "10px 10px 3px 40px rgba(255, 255, 255, 0.03) inset",
        cursor: "not-allowed",
        width:'15rem'
      }}
    >
      <p className="text-sm font-medium">Request Sent</p>
    </div>
  ) : (
    <div className="flex flex-row justify-center items-center gap-4">
      <CustomInput
        className={"flex-1"}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={{
          height: "51px",
          width: "100%",
        }}
        error={error}
        setError={setError}
        type={"email"}
        placeholder={"Company Email"}
        icon={mailicon}
        disabled={suceess}
      />
      <CustomButton
        className={"text-md w-[15rem]"}
        disabled={!email || isLoading}
        onClick={onClick}
        isLoading={isLoading}
      >
        Request Demo
      </CustomButton>
    </div>
  );
}

export default InviteInstitutionals;
