import React from 'react'
import CustomButton from '../components/Buttons/CustomButton'
import { useNavigate } from 'react-router-dom'
import Divider from '../components/Divider/Index';

function Thesis() {
    const navigate = useNavigate();
  return (
    <div  data-aos="fade-in-out" data-aos-delay={`100}`}>
        <div className='flex flex-row justify-center items-center gap-2'>
            <div className='w-[520px] py-[107px] flex flex-col gap-2'>
                <p className='text-5xl font-bold w-full h-full'>Fairer future with</p>
                <p className='text-5xl font-bold w-full h-full'>Smarter Investing</p>
            </div>
            <Divider style={{ width: '1px', height: '32rem'}} className={""} /> 
            <div className='flex flex-col text-center w-[589px] p-[107px] gap-2'>
                <p className='text-xl font-normal w-full h-full'>
                    Aspiring to financial freedom is a shared goal, yet the prevailing narrative implies not everyone possesses the tools and knowledge required. Whether accurate or not, we argue that this narrative hinders contemporary economic success significantly.
                </p>
                <p className='text-xl font-normal w-full h-full'>In the era of advanced technology, we believe the essential tools and knowledge for proficient wealth creation should be accessible to anyone with the determination to succeed. That's why we are committed to eliminating obstacles for those aspiring to achieve financial freedom.</p>
            </div>
        </div>
        <p className='text-4xl font-medium pb-[70px] h-[177px] text-center'>Want to get in touch? Click below.</p>
        <div className='flex flex-row justify-evenly items-center gap-2 pb-[140px] '>
            <div className='flex flex-col justify-center items-center gap-[18px]'>
                <p className='text-xl font-medium text-[#EFEFEF]'>Institutional?</p>
                <CustomButton 
                onClick={()=>navigate("/institutions#waitlist-instituion")}
                 className='text-md w-[10rem]'
                >Request Demo</CustomButton>
            </div>
            <div className='flex flex-col justify-center items-center gap-[18px]'>
                <p className='text-xl font-medium text-[#EFEFEF]'>Individual?</p>
                <CustomButton className='text-md w-[12rem]' onClick={()=>navigate("/#waitlist-1")}>Secure your Place</CustomButton>
            </div>
        </div>
    </div>
  )
}

export default Thesis