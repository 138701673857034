import React from 'react'
function Divider({ className, style, ...extraProps }) {
    return (
        <div className={className} style={{
            background: 'rgba(255, 255, 255, 0.20)',
            ...style
        }} {...extraProps}></div>
    )
}

export default Divider