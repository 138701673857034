import React, { useEffect } from "react";
import CommonSteps from "./CommonSteps";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Divider from "../../../Divider/Index";

function ActivePassive({ active, setActive }) {
  const stepData = React.useMemo(
    () => [
      {
        title: "Choose ETF or Index",
        bottom: ["100+", "ETFS / Indexes"],
      },
      {
        title: "Customize",
        bottom: ["Unlimited", "Customization"],
      },
      {
        title: "Get Your Portfolio",
        bottom: ["Real-time", "Results"],
      },
    ],
    []
  );
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-row gap-2 py-5 justify-center items-center">
        <p
          className={`text-xl font-medium`}
          style={{
            color: active === 1 ? "#00E7FF" : "#B1A8A8",
            cursor: "pointer",
          }}
          onClick={() => setActive(1)}
        >
          Active
        </p>
        <hr
          style={{
            fontSize: "2rem",
            width: "2px",
            height: "12px",
            backgroundColor: "#FFF",
          }}
        />
        <p
          className={`text-xl font-medium`}
          style={{
            color: active === 0 ? "#00E7FF" : "#B1A8A8",
            cursor: "pointer",
          }}
          onClick={() => setActive(0)}
        >
          Passive
        </p>
      </div>

      <div className="flex flex-row justify-center items-center gap-2 py-5">
        {stepData.map((step, index) => (
          <div className="flex" key={index}>
            <CommonSteps
              title={step.title}
              bottom={step.bottom}
              active={active}
              index={index + 1}
              data-aos="slide-right"
              data-aos-delay={`${(index + 1) * 100}`}
            />
            {index !== 2 && (
              <Divider
                className={"w-[1px] h-[26.5rem] mt-20"}
                style={{
                  backgroundColor: "1px solid rgba(239, 239, 239, 0.75)",
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ActivePassive;
