import React, { useState } from "react";
import ReactTyped from "react-typed";
import CustomCarousel from "../components/pageComponents/Instituionals/Carousel";
import InviteInstitutionals from "../components/pageComponents/Instituionals/InviteInstitutionals";
import Divider from "../components/Divider/Index";
import signupAPI from "../utils/Signup";
function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function Institutional() {
  const [email, setEmail] = useState("");
  const [suceess, setSuccess] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState("");
  const onClick = async () => {
    setIsloading(true);
    if(isLoading && !email) return;
    try {
      const res = await signupAPI(email, "institutional");
      console.log(res);
      setSuccess(true);
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      console.log(error);
      setError(true);
    }
  };
  const paras = React.useMemo(
    () => [
      {
        title: "HIGHLY CURATED",
        content:
          "Your clients seek flexibility incorporate customized direct indexing to reflect their choices",
      },
      {
        title: "TAX EFFICIENCY",
        content:
          "Provide your key clients with the capability to automatically update their portfolios for maximizing tax returns",
      },
      {
        title: "FULLY INTEGRATED",
        content:
          "Fits seamlessly into your line of products to provide holistic tax management and zero interruptions",
      },
    ],
    []
  );
  return (
    <div
      className="flex flex-col h-full w-full"
      data-aos="fade-in-out"
      data-aos-delay={`100}`}
    >
      <div className="mt-[234px] mb-[181px] mx-[5rem]">
        <div className="flex flex-row h-[20rem] justify-center items-start">
          <div className="flex flex-col text-5xl font-bold w-[500px] ml-[40px] gap-2 pt-2">
            <div className="text-[#00E7FF]">Bespoke </div>
            <div className="text-[#00E7FF]">Personalization</div>
            <div className="text-white">for your client portfolios.</div>
          </div>
          <Divider style={{ width: '1px', height: '13rem'}} className={" mt-[1rem]"} /> 
          <div className="flex-1 flex flex-col justify-between items-start gap-12 w-[589px] ml-[5rem] py-[1rem]">
            <div
              className="flex flex-col text-2xl font-bold mb-[25px]"
              id="waitlist-instituion"
            >
              <div className="text-[#00E7FF]">The future starts here...</div>
              <div className="h-[2rem]">
                <ReactTyped
                  strings={[
                    "Direct indexing",
                    "Factor-based strategies",
                    "Modern, client-first approach to investing",
                  ]}
                  typeSpeed={100}
                  loop
                  backSpeed={20}
                  cursorChar="I"
                  style={{
                    color: "#FFF",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                  }}
                  showCursor={true}
                />
              </div>
            </div>
            <InviteInstitutionals 
              email={email}
              setEmail={setEmail}
              isLoading = {isLoading}
              setIsloading = {setIsloading}
              suceess={suceess}
              setSuccess={setSuccess}
              onClick={onClick}
              setError={setError}
              error={error}
             />
          </div>
        </div>
      </div>
      
      <div className="flex flex-col justify-center items-center gap-2 mb-[8rem] mt-[2.5rem]">
        <p className="text-4xl font-medium">Differentiated Client Experience</p>
        <p
          className="text-2xl font-medium text-[#EFEFEF] text-center"
          style={{
            color: "rgba(239, 239, 239, 1)",
          }}
        >
          Alunar provides asset managers the ability to customize their clients’
          portfolios to suit their unique financial goals and automate tax
          savings.{" "}
        </p>
      </div>
      <Divider
        className={"h-[1px] w-full"}
        style={{
          background: "#EFEFEF",
        }}
      />
      <div className="flex flex-row justify-between items-center gap-3 py-[50px]">
        {paras.map((para, index) => (
          <div
            key={index}
            className="flex flex-col justify-center items-center gap-2"
          >
            <p
              className="text-xl font-bold"
              style={{
                color: "rgba(0, 231, 255, 1)",
              }}
            >
              {para.title}
            </p>
            <p
              className="text-lg font-normal text-center "
              style={{
                color: "rgba(239, 239, 239, 1)",
              }}
            >
              {para.content}
            </p>
          </div>
        ))}
      </div>
      
      <CustomCarousel
        data-aos={"slide-left"}
        data-aos-delay={`100}`}
        texts={[
          "Seamlessly blend investor-crafted technology with your firm's philosophy to incorporate personalized client requests. ",
          "Scalable investment solutions capable of managing thousands of individual client portfolios. ",
          "Nobel prize winning approach to optimize highest possible returns based on your client's portfolio risk. "
        ]}
      />
      
      <div className="py-[8rem]">
        <div className="flex flex-row h-[10rem] justify-center items-start">
          <div className="mx-16 flex-1 flex flex-col  justify-center items-center flex-wrap text-5xl font-bold w-[500px] ml-[40px] mr-[54px]">
            <div className="w-[500px]">
              The future of asset Management is Personal.
            </div>
          </div>
          <div className="flex flex-col justify-center items-center h-full w-full">
            <InviteInstitutionals 
              email={email}
              setEmail={setEmail}
              isLoading = {isLoading}
              setIsloading = {setIsloading}
              suceess={suceess}
              setSuccess={setSuccess}
              onClick={onClick}
              setError={setError}
              error={error}
             />
          </div>
        </div>
      </div>
      <Divider className={"h-[1px] w-full"} />
    </div>
  );
}

export default Institutional;
