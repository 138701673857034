import React from 'react';
import Linkedin from "../../assets/logo/in.svg";
import Instagram from "../../assets/logo/insta.svg";
import Twitter from "../../assets/logo/x.svg";
import { Link } from 'react-router-dom';

function BottomNavigation() {
    const socialLink = React.useMemo(() => [
        {
            link: "https://www.instagram.com/alunar.io/",
            icon: Instagram
        },
        {
            link: "https://twitter.com/Alunar_io",
            icon: Twitter
        },
        {
            link: "https://www.linkedin.com/company/alunar-io/",
            icon: Linkedin
        }
    ], []);

    return (
        <div className='my-div'>
        <div className='flex flex-col sm:flex-row justify-between items-center gap-4 py-5'>
            <div className='flex flex-1 items-center justify-center'>
                <p className='text-lg' style={{ color: 'rgba(239, 239, 239, 0.75)' }}>
                    © 2024 Astro Portfolio, Inc.
                </p>
            </div>
            <div className='flex flex-1 flex-col items-center gap-0.25'>
                <p className='text-lg' style={{ color: 'rgba(239, 239, 239, 0.75)' }}>
                    Questions?
                </p>
                <p className='text-lg' style={{ color: 'rgba(239, 239, 239, 0.75)' }}>
                    Send us an email at &nbsp;<a href='mailto:beta@alunar.io' style={{
                    color: "#00E7FF !important"
                    }}>beta@alunar.io</a>
                </p>
            </div>
            <div className='flex flex-1 flex-col items-center gap-3 sm:flex-row'>
                <p className='text-lg' style={{ color: 'rgba(239, 239, 239, 0.75)' }}>
                    Connect with us!
                </p>
                <div className='flex flex-row gap-2 items-center'>
                    {socialLink.map((item, index) => (
                        <Link to={item.link} key={index} target="_blank" rel="noopener noreferrer">
                            <img src={item.icon} alt={item} height={"21px"} width={"21px"} />
                        </Link>
                    ))}
                </div>
            </div>
            {/* Add Privacy Policy and Terms and Conditions links here */}
            <div className='flex flex-1 flex-col items-center gap-1 sm:flex-row'>
                <p className='text-sm' style={{ color: 'rgba(239, 239, 239, 0.75)' }}>
                    <Link to="/privacy-policy" style={{ color: '#00E7FF' }}>Privacy Policy</Link>
                </p>
            </div>
        </div>
        </div>
    );
}

export default BottomNavigation;
