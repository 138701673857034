import React, { useState } from "react";
import "./CustomInput.css";
function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function CustomInput({
  placeholder,
  type,
  value,
  onChange,
  className,
  icon = null,
  error = null,
  setError = null,
  ...rest
}) {
  const handleInputChange = (e) => {
    if (type === "email") {
      if (!validateEmail(e.target.value)) {
        setError("Invalid email address");
      } else {
        setError("");
      }
    }
    if (onChange) {
      onChange(e);
    }
  };
  return (
    <div
      className={`customInput flex flex-row px-3 py-1 gap-3 ${error ? 'error' : ''}`}
      style={rest?.style}
    >
      {icon && (
        <div className="inputIcon flex justify-center items-center">
          <img src={icon} alt="icon" className="h-5" />
        </div>
      )}
       <input
        type={type}
        onChange={handleInputChange}
        className={`bg-transparent text-white border-none outline-none w-full `}
        placeholder={placeholder}
      />
    </div>
  );
}

export default CustomInput;
