import React, { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './carousel.css'; // Import the CSS file

const CustomCarousel = ({ texts }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    let interval = null;

    const handleNext = () => {
        if(interval){
            clearInterval(interval)
        }
        setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    };
    
    const handlePrev = () => {
        if(interval){
            clearInterval(interval)
        }
        setCurrentIndex((prevIndex) => (prevIndex - 1 + texts.length) % texts.length);
    };

    useEffect(() => {
        if(interval){
            clearInterval(interval);
        }
        interval = setInterval(handleNext, 5000); // Change the interval duration as needed
        return () => clearInterval(interval);
    }, [handleNext, handlePrev]);

    return (
        <div className='flex flex-row justify-between gap-5 my-[5rem] mb-[10rem]'>
            <button onClick={handlePrev} style={{
                display: currentIndex == 0?"none":"inline-block",
                cursor:"pointer"
            }}>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='#fff'
                >
                    <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M15 19l-7-7 7-7'
                    />
                </svg>
            </button>
            <TransitionGroup>
                <CSSTransition
                    key={currentIndex}
                    timeout={500}
                    classNames={"slide"}
                >
                    <p className='text-2xl font-normal text-center'
                        style={{
                            color:"rgba(239, 239, 239, 1)"
                        }}
                    >
                        {texts[currentIndex]}
                    </p>
                </CSSTransition>
            </TransitionGroup>
            <button onClick={handleNext} style={{
                cursor: "pointer",
                display: currentIndex === texts.length - 1?"none":"inline-block",
            }}>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='#fff'
                >
                    <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M9 5l7 7-7 7'
                    />
                </svg>
            </button>
        </div>
    );
};

export default CustomCarousel;