import React from 'react';
import './App.css';
import NavBar from './components/Navbar/Index';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import Privacy from './pages/Privacy';
import WhiteDot from './components/extras/WhiteDot';
import BottomNavigation from './components/BottomNavigation/Index';
import Thesis from './pages/Thesis';
import Institutional from './pages/Institutional';

const router = createBrowserRouter([

  {
    path: "/",
    element:
      <>
        <Home />
        <BottomNavigation/>
      </>
  },
  {
    path: "/privacy-policy",
    element:
      <>
        <Privacy />
        <BottomNavigation/>
      </>
  },
  {
    path: "/institutions",
    element:
      <>
        <NavBar />
        <Institutional/>
        <BottomNavigation/>
      </>
  },
  {
    path: "/thesis",
    element:
      <>
        <NavBar />
        <Thesis/>
        <BottomNavigation/>
      </>
  }
]);


function App() {
  return (
    <div className='h-screen w-screen'>
      <div>
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
