import React from "react";
import CustomButton from "../../Buttons/CustomButton";
import CustomInput from "../../Inputs/CustomInput";
import MailIcon from "../../../assets/icons/mail-con.svg";

function Waitlist({
  email,
  setEmail,
  suceess,
  isLoading,
  error,
  setError,
  onClick,
}) {
  return suceess ? (
    <div
      className="flex flex-row justify-around items-center p-[17px] gap-3"
      style={{
        borderRadius: "14px",
        border: "1.4px solid ",
        borderColor: "#00E7FF",
        boxShadow: "10px 10px 3px 40px rgba(255, 255, 255, 0.03) inset",
        cursor: "not-allowed",
        width: "15rem",
      }}
    >
      <p className="text-sm font-medium">Request Sent</p>
    </div>
  ) : (
    <div className="w-full flex flex-col items-center gap-3">
      <CustomInput
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className={"flex-1"}
        style={{
          width: "300px",
          height: "51px",
        }}
        error={error}
        setError={setError}
        disabled={suceess}
        type={"email"}
        placeholder={"Email"}
        icon={MailIcon}
      />
      
      <CustomButton
        disabled={!email || isLoading}
        onClick={onClick}
        isLoading={isLoading}
        className={"text-md w-[12rem]"}
      >
        Secure your Spot
      </CustomButton>
    </div>
  );
}

export default Waitlist;