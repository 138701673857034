import React from 'react'
import MajorLogo from "../../assets/logo/logoMajor.png"
import { useNavigate, useLocation } from 'react-router-dom'
function NavBar() {
  const navigate = useNavigate();
  const location = useLocation();

  const navigation = React.useMemo(() => [
    {
      name: 'Individual Investors',
      link: '/',
    },
    {
      name: 'Institutional',
      link: '/institutions',
    },
    {
      name: 'Our Thesis',
      link: '/thesis',
    },
  ], [])
console.log({
  location
})
  return (
    <div className='w-100 flex justify-between items-center py-10 z-50'>
      <img src={MajorLogo} alt="Major Logo" className='h-5 ml-10' style={{
        width: '196px',
        height: '36.296px',
        flexShrink: 0,
      }} />
      <div className='flex justify-between items-center'>
        {navigation.map(({ name, link }) => (
          
          <p
          onClick={()=>{
            navigate(link)
          }}
           className={`${location?.pathname == link?'text-white':"text-[#B1A8A8]"} hover:text-white hover:cursor-pointer text-lg mx-4 font-bold`}
            key={name}
          >
            {name}
          </p>
        ))}
      </div>
    </div>
  )
}

export default NavBar