import React, { useState, useMemo } from 'react';
import ReactTyped from 'react-typed';
import ReMap from '../components/pageComponents/main/ReMap';
import phoneScreenImage from '../assets/icons/phone-screen.png';
import Divider from '../components/Divider/Index';
import AOS from 'aos';
import 'aos/dist/aos.css';
import videoGif from "../assets/logo/gif-video.mp4";
import ExtraFeatures from "../components/pageComponents/main/ExtraFeatures";
import { ReactComponent as Icon1 } from "../assets/icons/icon-1.svg";
import { ReactComponent as Icon2 } from "../assets/icons/icon-2.svg";
import { ReactComponent as Icon3 } from "../assets/icons/icon-3.svg";
import WhiteDot from "../components/extras/WhiteDot";
import Waitlist from "../components/pageComponents/main/Waitlist";
import ActivePassive from "../components/pageComponents/main/ActivePassive/ActivePassive";
import signupAPI from "../utils/Signup";
import MajorLogo from "../assets/logo/logoMajor.png"; 

AOS.init({
  duration: 2000,
});

function Home() {
  const [activeTab, setActiveTab] = useState(1);
  const [email, setEmail] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  const handleSubmit = async () => {
    setIsLoading(true);
    if (isLoading || !email) return;
    try {
      await signupAPI(email, "Retail");
      setIsSubscribed(true);
  
      // Check if the Facebook pixel's fbq function is available
      if (window.fbq) {
        // Track the "Subscribe" event with Facebook Pixel
        window.fbq('track', 'Subscribe');
      } else {
        console.error('Facebook Pixel not initialized');
      }
  
    } catch (error) {
      setApiError(error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const videoFeatures = useMemo(
    () => [
      {
        title: "BUILT-IN TRADING TECH",
        data: "Auto-analysis of the optimal risk and return using proven strategies employed by leading hedge funds and investors",
      },
      {
        title: "SMART STOCK CURATION",
        data: "Select stocks based on factors that align with your values, guided by our proven market analysis",
      },
      {
        title: "ALL-IN-ONE PLATFORM",
        data: "Craft a data-backed strategy with ease and seamlessly execute with your brokerage of choice",
      },
      {
        title: "AI POWERED HAND HOLDING",
        data: "Sleep well at night knowing that your investing is supported by AI research and strategic confidence",
      },
    ],
    []
  );

  const extraFeaturesData = useMemo(
    () => [
      {
        icon: <Icon1 />,
        data: "Your brokerage of choice",
      },
      {
        icon: <Icon2 />,
        data: "Free 14-day trial ",
      },
      {
        icon: <Icon3 />,
        data: "You own your data",
      },
    ],
    []
  );

  return (
    <div className='my-div'>
    <div
      className="flex flex-col h-full w-full relative justify-center items-center"
      data-aos="fade-in-out"
      data-aos-delay={`100`}
    >
      
      <div
        className="flex flex-col justify-center items-center mt-[234px] mb-[181px]"
        style={{width: '400px', height: '100px'}}
      >
        <img src={MajorLogo} alt="Major Logo" className="logo" />
        <div className="mt-4 text-lg text-center font-bold">Mult-Asset UMA Optimization</div>
        <div className="text-lg text-center font-bold mt-20">Available Soon</div>
        <div className="flex flex-col h-full justify-center items-center gap-12 pt-5 mt-10">
          <div className="flex flex-col items-center text-2xl font-bold">
            <div className="text-lg text-[#00E7FF] text-center mt-40">Unlimited Personalization</div>
            <div className="h-[2rem]">
              <ReactTyped
                strings={[
                  "Fixed Income",
                  "Equities",
                  "Strategy Customization",
                  "Tax Efficient Transitions",
                  "Direct Indexing",
                  "Factor-Based Investing",
                  "Laddered Strategies",

                ]}
                typeSpeed={100}
                loop
                backSpeed={20}
                cursorChar="I"
                style={{
                  color: "#FFF",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                }}
                showCursor={true}
              />
            </div>
          </div>
            <div
              className="flex flex-col justify-between items-center w-full mt-[-20px]"
              id="waitlist-1"
            >
    
          <Waitlist
            email={email}
            setEmail={setEmail}
            isLoading={isLoading}
            setIsloading={setIsLoading}
            suceess={isSubscribed}
            setSuccess={setIsSubscribed}
            onClick={handleSubmit}
            setError={setApiError}
            error={apiError}
          />
        </div>
        </div>
      </div>
      <Divider className="mt-[120px] w-full h-[1px] min-w-[300px]" />
    </div>
    </div>
    
  );
}

export default Home; 