const API_URL = "https://alunar-be.onrender.com";
const signupAPI = async (email, role) => {
  const response = await fetch(`${API_URL}/whiteList/sign-up`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, role, name: "." }),
  });
  const data = await response.json();
  return data;
};

export default signupAPI;