
import React from 'react';
import "./CustomButton.css"
function CustomButton({ children, onClick, otherProps, className, disabled=false, isLoading=false }) {
  console.log({
    disabled
  })
  return (
    <div className={` fancy ${className}`} {...otherProps}>
      <button className={`${className} ${disabled?"cursor-not-allowed" : "cursor-pointer"}`} onClick={onClick} {...otherProps} disabled={disabled}>
        {!isLoading? children:(
          <div className="flex flex-row justify-center items-center gap-2">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div>
            <div>Loading...</div>
          </div>
        )}
      </button>
    </div>
  );
}
export default CustomButton;